import React from "react";
import { Link } from "gatsby";
import Layout from "../layout/Layout";
import ProductDetailsUnregisterRoute from "../components/products/ProductDetailsUnregisterRoute";
import NotVisible from "../sections/product/NotVisible";

const NotFoundPage = ({ location }) => {
  const { state } = location;

  if (state?.message?.includes("product not visible")) {
    return <NotVisible />;
  } else {
    return (
      <Layout>
        {location.pathname.includes("products") ? (
          <ProductDetailsUnregisterRoute
            location={location}
            product_id={state?.product_id}
          />
        ) : (
          <section className="section">
            <div className="container">
              <div className="content">
                <div className="container">
                  <section className="bc-cart">
                    <div className="bc-cart__empty">
                      <h2 className="bc-cart__title--empty">Page Not Found</h2>
                      <p>
                        You've hit a page that doesn't exist. Looking to shop?
                      </p>
                      <Link
                        to="/products"
                        className="bc-cart__continue-shopping"
                      >
                        Take a look around.
                      </Link>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        )}
      </Layout>
    );
  }
};

export default NotFoundPage;
